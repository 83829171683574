<template>
    <section>

        <div class="jewelry_page" v-if="page === 'jewelry'">
            <div class="hues_block">
                <div class="">
                    <picture>
                        <!-- <source media="(min-width: 768px)" width="1903" height="656" type="image/webp" srcset="~/assets/landing/jewelry/christmas/jewelrybanner.webp"> -->
                        <source media="(min-width: 992px)" width="3540" height="740" type="image/jpeg"
                            srcset="~/assets/spring/jewerly/alphabet.jpg">

                        <source media="(min-width: 992px)" width="3540" height="740" type="image/webp"
                            srcset="~/assets/spring/jewerly/alphabet.webp">

                        <source  media="(min-width: 768px) and (max-width: 991px)" width="1864" height="620" type="image/webp"
                            srcset="~/assets/spring/jewerly/alphabet_small_new.webp">

                        <source  media="(min-width: 768px) and (max-width: 991px)" width="1864" height="620" type="image/jpeg"
                            srcset="~/assets/spring/jewerly/alphabet_small_new.jpg">
                        <!-- <source media="(max-width: 767px)" width="767" height="349" type="image/webp" srcset="~/assets/landing/jewelry/christmas/jewelrybanner_mob.webp"> -->
                        <source media="(max-width: 767px)" width="1372" height="1640" type="image/jpeg"
                            srcset="~/assets/spring/jewerly/alphabet_mob.jpg">

                        <source media="(max-width: 767px)" width="1372" height="1640" type="image/webp"
                            srcset="~/assets/spring/jewerly/alphabet_mob.webp">

                        <img alt="Inspired by the radiance in you!" class="radious_eight" width="3540" height="740"
                            type="image/jpeg" src="~/assets/spring/jewerly/alphabet.jpg">
                    </picture>
                </div>

                <div class="upper_text upper_text_mod jewel_mod">
                    <p class="uppercase jewel_intro">Introducing</p>
                    <div class="jewel_space">
                        <h3 class="font-active jewel_letter">Love Letters</h3>
                        <p class="jewel_coming">Personalized brilliance for our deepest sentiments.</p>
                    </div>
                    <div class="initial_anker">
                        <NuxtLink class="anker radious_four btn-p-animation"
                            :to="localePath({ name: 'shop-initial-necklace' })">
                            <span>Shop Initial Necklaces</span>
                        </NuxtLink>
                    </div>
                </div>

            </div>
        </div>

        <div class="container" v-else>
            <div class="row">
                <div class="col-lg-6 cols-12 mb-4 mb-lg-0">
                    <div class="hues_block">
                        <picture>
                            <source media="(min-width: 992px)" width="1738" height="1108" type="image/webp"
                                srcset="~/assets/spring/hues.webp">
                            <source media="(max-width: 991px)" width="1372" height="1780" type="image/webp"
                                srcset="~/assets/spring/hues_mob.jpg">
                            <img loading="lazy" fluid width="1738" height="1108" type="image/jpeg"
                                src="~/assets/spring/hues.jpg" alt="Hues Rings">
                        </picture>
                        <div class="upper_text">
                            <img class="hues_logo" src="~/assets/spring/hues.svg" height="152" width="217" alt="">
                            <p class="my-2">Colored Diamond Engagement Rings</p>
                            <p class="mb-3">Every hue from dawn to dusk - captured in diamonds.</p>
                            <router-link :to="localePath({ path: '/high-jewelry/hues-rings'})">Discover
                                Hues</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6  cols-12">
                    <div class="hues_block">
                        <picture>
                            <source media="(min-width: 992px)" width="1738" height="1108" type="image/webp"
                                srcset="~/assets/spring/loveletters.webp">
                            <source media="(max-width: 991px)" width="1372" height="1780" type="image/webp"
                                srcset="~/assets/spring/love_mob.jpg">
                            <img loading="lazy" fluid width="1738" height="1108" type="image/jpeg"
                                src="~/assets/spring/loveletters.jpg" alt="Love Letters">
                        </picture>
                        <div class="upper_text upper_text_mod">
                            <p class="mb-2 uppercase">Introducing</p>
                            <h3 class="font-active">Love Letters</h3>
                            <p>Personalized brilliance for our <br> deepest sentiments.</p>
                            <router-link :to="localePath({ name: 'shop-initial-necklace' })">Shop Initials</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </section>
</template>

<script setup>
const localePath = useLocalePath()
const {page} = defineProps(['page'])
</script>

<style scoped>
.hues_block{
    position: relative;
}
.hues_block img{
    border-radius: 8px;
}
.upper_text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(0, -50%);
}

.upper_text p {
    font-size: 14px;
    color: #eaeaea;
}

.upper_text a {
    border: 1.5px solid #fff;
    border-radius: 4px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 180px;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
}

.uppercase{
    text-transform: uppercase;
    letter-spacing: 2px;
}
.upper_text h3 {
    font-size: 32px;
    color: #fff;
}

.upper_text h4 {
    color: #eaeaea;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 2px;
}
.upper_text.upper_text_mod {
    left: 63%;
}
.jewel_mod .jewel_intro{
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 0;
}
.jewel_mod .anker{
    min-width: 252px;
    max-width: 300px;
    font-size: 14px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    height: 45px;
}

.jewel_mod .jewel_letter{
    font-weight: 400;
    font-size: 32px;
    letter-spacing: 0;
    margin-bottom: 8px;
}
.jewel_mod .jewel_coming {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0;
    margin: 0;
}
.upper_text.upper_text_mod .jewel_space{
    margin: 24px 0;
}
/* jewelry */
@media (max-width :1450px) {
    .upper_text.upper_text_mod .jewel_space {
        margin: 16px 0;
    }
    .upper_text.upper_text_mod.jewel_mod .jewel_letter {
        font-size: 24px;
    }

    .upper_text.upper_text_mod.jewel_mod .jewel_coming {
        font-size: 16px;
    }
    .upper_text.upper_text_mod {
        left: 55%;
    }
    img.hues_logo {
        width: 70%;
    }
}

@media (max-width :992px){
    /* jewelry */
    .upper_text.upper_text_mod .jewel_space {
        margin: 18px 0;
    }
    /* .upper_text.upper_text_mod.jewel_mod{
        left: 50%;
        text-align: center;
        transform: translate(-50%, 50%);
    } */
    .jewel_mod .initial_anker a{
        /* margin: 0 auto; */
    }
}

@media(max-width: 991px){
    .upper_text {
        transform: translate(-50%, -20%);
        text-align: center;
        width: 85%;
    }
    .upper_text a{
        margin: 0 auto;
        max-width: 100%;
        max-width: 352px;
    }
    .upper_text.upper_text_mod {
        left: 50%;
        transform: translate(-50%, 20%);
    }
    img.hues_logo {
        width: 50%;
    }
    .upper_text.upper_text_mod .jewel_space {
        margin: 10px 0;
    }
    .upper_text.upper_text_mod.jewel_mod {
        top: 50%;
        left: 54%;
        max-width: 43%;
        text-align: left;
        transform: translate(0%, -50%);
    }
    .upper_text.upper_text_mod.jewel_mod a {
        margin: 0;
    }
    .upper_text.upper_text_mod.jewel_mod .jewel_intro {
        font-size: 14px;
        letter-spacing: 1.5px;
    }

    .upper_text.upper_text_mod.jewel_mod .jewel_letter {
        font-weight: 400;
        font-size: 24px;
        letter-spacing: 0;

    }

    .upper_text.upper_text_mod.jewel_mod .jewel_coming {
        font-size: 14px;
        letter-spacing: 0;
    }

    .upper_text.upper_text_mod.jewel_mod h4 {
        font-size: 14px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
    }
    .upper_text.upper_text_mod.jewel_mod .jewel_letter {
        margin-bottom: 4px;
    }

}

@media (max-width : 767px){
    .upper_text.upper_text_mod.jewel_mod {
        left: 50%;
        text-align: center;
        max-width: 100%;
        transform: translate(-50%, 20%);
    }
    .upper_text.upper_text_mod.jewel_mod a {
        margin: 0 auto;
    }
}
</style>